import './RhythmText.css';

import React from 'react';

import * as Consts from './RhythmConsts.js';
import { RhythmContext } from './RhythmContext.js';
import { useConductedAnimationFrame, useUniqueID } from './RhythmHook.js';

export function RhythmInfo({name}) {
    return (
	<div className="songInfo">
	    <h1 className="songName">{name}</h1>
	</div>
    );
}

export function RhythmFeedback({scoringFn=null}) {
    const componentId = useUniqueID(); //React.useId();
    const [text, setText] = React.useState('');
    const [show, setShow] = React.useState('');
    const [random, setRandom] = React.useState('');
    
    const { conductorRef } = React.useContext(RhythmContext);
    
    useConductedAnimationFrame((cb, pb) => {
	// websocket submit answer calls this
	let score = conductorRef.current.scoreAnswer(pb);
	
	let message = '';
	if (scoringFn) {
	    message = scoringFn(score);
	} else if (score === 1000) {
	    message = 'PERFECT!';
	//} else if (score > 900) {
	//    message = 'FABULOUS!';
	//} else if (score > 850) {
	//    message = 'GREAT!';
	//} else if (score > 600) {
	//    message = 'Good!';
	} else if (score === Consts.NEXT_LEVEL) {
	    message = '=== LEVEL UP ===';
	} else if (score === Consts.GET_READY) {
	    message = '=== GET READY ===';
	} else {
	    message = 'TRY AGAIN!';
	}
	setText(message);
	setRandom(Math.random() + text);
    }, componentId, Consts.BAR - Consts.NOTE_16);

    return (
	<div key={random} className={"melting-text-container " + show}>
	    <h1 data-text={text} className="melting-text">{text}</h1>
	</div>
    );
}
