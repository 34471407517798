import React from 'react';
import {
    Alert,
    Button,
    Card,
    Form,
    InputGroup,
    Modal
} from 'react-bootstrap';

import { fetchLoginStatus, getWithData, postWithToken } from '../common/SecurityUtils.js';
import SiteURL from '../common/SiteURL.js';
import Loading from '../common/Loading.js';

export default function EventCreation({eventID, ticketH}) {
    const [isLoaded, setIsLoaded] = React.useState(false);

    const [message, setMessage] = React.useState('');
    const [validated, setValidated] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const [name, setName] = React.useState(null);
    const [sevent, setSevent] = React.useState(null);
    const [desc, setDesc] = React.useState(null);
    const [status, setStatus] = React.useState(null);

    React.useEffect(() => {
        setIsLoaded(true);
    }, []);

    const handleCreateEvent = React.useCallback((newonly = false) => (e) => {
        setIsLoaded(false);

	let eventData = {
	    name: name,
	    desc: desc,
	    sevent: sevent,
	};
	
        postWithToken('/event/create_event/', eventData)
            .then(res => res.json())
            .then(
                (res) => {
                    setMessage(res.msg);
                    setIsLoaded(true);
                },
                (error) => {
                    setMessage(error.msg);
                    setIsLoaded(true);
                },
            );
    }, [name, desc, sevent]);
    
    if (!isLoaded) {
        return <Loading />;
    }

    return (
	<Card className="my-3">	    
	    <Card.Header>
		Create Event
	    </Card.Header>
	    <Card.Body>
		{message && <Alert>{message}</Alert>}
		<Form noValidate validated={validated}>
		    <InputGroup size="sm" className="mb-3" controlId="formName">
			<InputGroup.Text>Name</InputGroup.Text>
			<Form.Control
			    value={name}
			    type="text"
			    placeholder="Event Name"
			    onChange={e => setName(e.target.value)}
			/>
		    </InputGroup>
		    <InputGroup size="sm" className="mb-3" controlId="formDesc">
			<InputGroup.Text>Description</InputGroup.Text>
			<Form.Control
			    value={desc}
			    type="text"
			    placeholder="Event Description"
			    onChange={e => setDesc(e.target.value)}
			/>
		    </InputGroup>
		    <InputGroup size="sm" controlId="formSevent">
			<Form.Check
			    type="switch"
			    checked={sevent}
			    label="Singing Event"
			    onChange={e => setSevent(p => !p)}
			/>
		    </InputGroup>
		</Form>
	    </Card.Body>
	    <Card.Footer>
		<Button
		    className="mx-2"
		    onClick={handleCreateEvent()}>
		    Create
		</Button>
	    </Card.Footer>
	</Card>

    );
}
