import './RhythmGame.css';

import React from 'react';

import { RhythmOrbit } from './RhythmNotes.js';
import { useConductedAnimationFrame, useUniqueID } from './RhythmHook.js';
import * as Consts from './RhythmConsts.js';
import { RhythmContext } from './RhythmContext.js';
import { RhythmFeedback, RhythmInfo } from './RhythmFeedback.js';
import RhythmLyrics from './RhythmLyrics.js';
import RhythmGenerator from './RhythmGenerator.js';
import RhythmMeter from './RhythmMeter.js';
import RhythmScorer from './RhythmScorer.js';
import RhythmQR from './RhythmQR.js';
import { RhythmSheet } from './RhythmSheet.js';

import { RhythmBackground, RhythmFlash } from './RhythmBackground.js';

export default function RhythmGame() {
    const componentId = useUniqueID();//React.useId();
    const { conductorRef } = React.useContext(RhythmContext);
    
    const [flash, setFlash] = React.useState(false);
    const [band, setBand] = React.useState('flatdog');
    const [name, setName] = React.useState('name');
    const [song, setSong] = React.useState(null);
    const [started, setStarted] = React.useState(false);
    
    const [connected, setConnected] = React.useState(false);
    
    const userBeat = React.useCallback((time) => {
	conductorRef.current.beat();
	setFlash(true);
	setTimeout(() => setFlash(false), 150); // Remove flash class right after
    }, []);

    // This hook will only run once each when the conductor is null and when
    // the reference is set. Don't except anything else when the internal
    // guts of the conductor changes.
    React.useEffect(() => {
	if (!conductorRef.current) {
	    return;
	}
	onSongChange(conductorRef.current.currentSong);
	conductorRef.current.subscribeSongChange(onSongChange, 'info');
    }, [conductorRef.current]);
        
    const onSongChange = React.useCallback((newSong) => {
	setName(newSong.getName());
	setSong(newSong);
    }, []);
    
    const handleKeyDown = React.useCallback((event) => {
	if (!conductorRef.current) {
	    return;
	}
	
	// console.log('pressed:' + event.key + '!');
	switch (event.key) {
	case 'w':
	    // Generates a random stage
	    conductorRef.current.loadSong(RhythmGenerator.genSimple1());
	    return;
	case 'e':
	    // Generates a random stage
	    conductorRef.current.loadSong(RhythmGenerator.genSimple2());
	    return;
	case 'r':
	    // Generates a random stage
	    conductorRef.current.loadSong(RhythmGenerator.genClockPuzzle());
	    return;
	case 't':
	    conductorRef.current.loadSong(RhythmGenerator.genLetterPuzzle());
	    return;
	case ',':
	    // testing increase bpm
	    conductorRef.current.setBPM(conductorRef.current.bpm + 5);
	    return;
	case '.':
	    // testing decreasing bpm
	    conductorRef.current.setBPM(conductorRef.current.bpm - 5);	    
	    return;
	    
	case 's':
	    conductorRef.current.start();
	    setStarted(true);
	    return;
	    
	case 'p':
	    conductorRef.current.stop();
	    setStarted(false);
	    return;
	case ' ':
	    userBeat(event.timeStamp);
	    return;
	default:
	    return;
	}
    }, []);
    
    // Register keylistener
    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            // unsubscribe event
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    useConductedAnimationFrame((cb, pb) => {
	let data = conductorRef.current.getGradableData()
    }, componentId, Consts.BAR - Consts.NOTE_16);
    
    return (
	<div className="gameLayout">
	    <RhythmMeter />
	    <div className="gameScreen">
		<RhythmBackground name={band} flash={flash} />
		<RhythmFlash flash={flash} />
		<RhythmSheet />
		<RhythmOrbit />
		<RhythmFeedback />
		<RhythmLyrics song={song} />
		<RhythmInfo name={name} />
	    </div>
	</div>
    );
}
