import React from 'react';

import * as Consts from './RhythmConsts.js';
import { useConductedAnimationFrame, useUniqueID } from './RhythmHook.js';

export function RhythmBeat({beat}) {
    let a = beat * 2 * Math.PI / Consts.BAR;
    let x = Consts.ORBIT_R * Math.sin(a);
    let y = -Consts.ORBIT_R * Math.cos(a);

    const style = {
	"--beat-pos-x": x.toFixed(4) + 'px',
	"--beat-pos-y": y.toFixed(4) + 'px',
    };
    return <div className="note" style={style} />;
}

function RhythmNote({note}) {
    const style = {
	"--beat-number": note.beat,
    }
    return <div className="note" style={style} />;
}

export function RhythmNotes({notes}) {
    // const conductorRef = React.useRef(null);
    return (
	<div className="notes">
	    {notes.map(n => <RhythmNote note={n} />)}
	</div>
    );
}

export function RhythmOrbit() {
    const componentId = useUniqueID();// React.useId();
    const [beat, setBeat] = React.useState(0);
    useConductedAnimationFrame((cb, pb) => {
	setBeat(prevBeat => (cb % Consts.BAR)); // / Consts.BAR);
    }, componentId);
    
    return <RhythmBeat beat={beat} />;
}
