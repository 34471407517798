import './RhythmGame.css';

import React from 'react';

import { RhythmOrbit } from './RhythmNotes.js';
import { useConductedAnimationFrame, useUniqueID } from './RhythmHook.js';
import { useWebSocket } from './RhythmSocket.js';
import * as Consts from './RhythmConsts.js';
import { RhythmContext } from './RhythmContext.js';
import { RhythmFeedback, RhythmInfo } from './RhythmFeedback.js';
import RhythmLyrics from './RhythmLyrics.js';
import RhythmGenerator from './RhythmGenerator.js';
import RhythmMeter from './RhythmMeter.js';
import RhythmScorer from './RhythmScorer.js';
import RhythmQR from './RhythmQR.js';
import { RhythmSheet } from './RhythmSheet.js';

import { RhythmBackground, RhythmFlash } from './RhythmBackground.js';
import {
    s,
    s2,
    GiantPeach,
    MoreThanWords,
    Illusions,
} from './Songs.js';


export default function RhythmHost() {
    const componentId = useUniqueID();//React.useId();
    const { conductorRef } = React.useContext(RhythmContext);
    
    const [flash, setFlash] = React.useState(false);
    const [band, setBand] = React.useState('flatdog');
    const [name, setName] = React.useState('name');
    const [song, setSong] = React.useState(null);
    const [started, setStarted] = React.useState(false);
    
    const [connected, setConnected] = React.useState(false);
    
    const userBeat = React.useCallback((time) => {
	conductorRef.current.beat();
	setFlash(true);
	setTimeout(() => setFlash(false), 150); // Remove flash class right after
    }, []);

    // This hook will only run once each when the conductor is null and when
    // the reference is set. Don't except anything else when the internal
    // guts of the conductor changes.
    React.useEffect(() => {
	if (!conductorRef.current) {
	    return;
	}
	onSongChange(conductorRef.current.currentSong);
	conductorRef.current.subscribeSongChange(onSongChange, 'info');
    }, [conductorRef.current]);

    
    const openCallback = (e) => {	
	setConnected(true);
    };
    
    const messageCallback = (e) => {
	let conductor = conductorRef.current;
        const data = JSON.parse(e.data);
	
        switch (data.type) {
        case 'graded':
	    if ('score' in data) {
		conductor.onlineScore(data['score']);
	    }
            break;
	default:
	    break;
	}
    };
    
    const closeCallback = (e) => {
	setConnected(false);
    };
    
    const sendMessage = useWebSocket(
	openCallback,
	messageCallback,
	closeCallback,
    );

    const sendUpdate = React.useCallback(() => {
	if (!connected) {
	    return;
	}
	
	let payload = conductorRef.current.getUpdatePayload();
	payload.type = 'update';
	payload.band = band;
	payload.started = started;
	sendMessage(payload);
    }, [connected, band, started]);

    React.useEffect(() => {
	sendUpdate();
    }, [band, name, song, started]);
    
    const onSongChange = React.useCallback((newSong) => {
	setName(newSong.getName());
	setSong(newSong);
    }, []);
    
    const handleKeyDown = React.useCallback((event) => {
	if (!conductorRef.current) {
	    return;
	}
	
	// console.log('pressed:' + event.key + '!');
	switch (event.key) {
	case 'q':
	    conductorRef.current.loadSong(Illusions.resetState());
	    
	    return;
	//case 'q':
	    //return conductorRef.current.loadSong(RhythmGenerator.transition());
	case 'w':
	    // Generates a random stage
	    conductorRef.current.loadSong(RhythmGenerator.genSimple1());
	    return;
	case 'e':
	    // Generates a random stage
	    conductorRef.current.loadSong(RhythmGenerator.genSimple2());
	    return;
	case 'r':
	    // Generates a random stage
	    conductorRef.current.loadSong(RhythmGenerator.genClockPuzzle());
	    return;
	case 't':
	    conductorRef.current.loadSong(RhythmGenerator.genLetterPuzzle());
	    return;
	case ',':
	    // testing increase bpm
	    conductorRef.current.setBPM(conductorRef.current.bpm + 5);
	    return;
	case '.':
	    // testing decreasing bpm
	    conductorRef.current.setBPM(conductorRef.current.bpm - 5);	    
	    return;
	    
	case 'l':
	    conductorRef.current.loadSong(MoreThanWords.resetState());
	    return;
	case 'k':
	    conductorRef.current.loadSong(GiantPeach.resetState());
	    return;
	    
	case 's':
	    conductorRef.current.start();
	    setStarted(true);
	    //if (t0) {
	    //sendMessage({
	    //type: 'start',
	    //start_time: t0,
	    //});
	    //}
	    return;
	    
	case 'p':
	    conductorRef.current.stop();
	    setStarted(false);
	    return;
	case ' ':
	    userBeat(event.timeStamp);
	    return;
	case 'c':
	    setBand('tuan');
	    return;
	case 'v':
	    setBand('flatdog');
	    return;
	case 'b':
	    setBand('palefire');
	    return;
	default:
	    return;
	}
    }, []);
    
    // Register keylistener
    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            // unsubscribe event
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    useConductedAnimationFrame((cb, pb) => {
	let data = conductorRef.current.getGradableData()
	data.type = 'grade';
	// sendMessage(data);
	sendMessage({
	    type: 'calibrate',
	    time: Date.now(),
	});
    }, componentId, Consts.BAR - Consts.NOTE_16);

    
    return (
	<div className="gameLayout">
	    <RhythmMeter />
	    <div className="gameScreen">
		{/*<RhythmQR />*/}
		<RhythmBackground name={band} flash={flash} />
		<RhythmFlash flash={flash} />
		<RhythmScorer />
		<RhythmSheet />
		<RhythmOrbit />
		<RhythmLyrics song={song} />
		<RhythmInfo name={name} />
	    </div>
	</div>
    );
}
