import React from 'react';
import { Button, Card, CardGroup, Col, Row } from 'react-bootstrap';

import Loading from '../common/Loading.js';
import ConfigurableMarkup from '../common/ConfigurableMarkup.js';
import PuzzleToast from '../common/PuzzleToast.js';
import SiteURL from '../common/SiteURL.js';

import { HardcodedEvents } from './EventHardcoded.js';
import EventTicketReservationForm from './EventTicketReservationForm.js';
import EventRecommendationsList from './EventRecommendationsList.js';
import EventShowProgram from './EventShowProgram.js';

export default function EventDetail({eventID, showForm=false}) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [eventData, setEventData] = React.useState([]);
    const [showShareToast, setShowShareToast] = React.useState(false);

    // Ran only once since there's no dependency (2nd arg to useEffect)
    React.useEffect(() => {
        setIsLoaded(false);
        fetch('/event/fetch_event/?event_id=' + eventID)
            .then(res => res.json())
            .then(
                  (payload) => {
                      setEventData(payload);
                      setIsLoaded(true);
                  },
                  (error) => {
                      setIsLoaded(true);
                  }
            )
    }, []);

    const handleShareEvent = () => {
        let message = 'You might be interested in this event "' + eventData.name + '": \n';
        message += new SiteURL()
            .event()
            .setParams({event_id:eventID})
            .getAbsolute();
        navigator.clipboard.writeText(message);
        setShowShareToast(true);
    };

    if (!isLoaded) {
        return <Loading />;
    }
    
    if (!(eventData.event_id in HardcodedEvents)) {
        let markup = eventData.markup;

        if (!markup) {
            return (
                <div>Event Not Found</div>
            );
        }
	let mapper = new ConfigurableMarkup().setMapper(
	    (tag, attr, inner) => {
		if (tag === 'program') {
		    return <EventShowProgram event={eventData.event_id} />;
		}
		return null;
	    }
	);
        let inner = (typeof markup === 'string')
            ? mapper.fromXML(new DOMParser().parseFromString(markup, "text/xml").firstChild)
            : mapper.fromJSON(markup);

        return (
            <div>
                {inner}

                <div className="eventShareButtonContainer">
                    <Button
	                    className="eventShareButton"
	                    variant="outline-primary"
	                    size="lg"
	                    onClick={handleShareEvent}>
	                    Share This Event!
                    </Button>
                    
                    <PuzzleToast
	                    onClose={() => setShowShareToast(false)}
	                    show={showShareToast}
	                    text="Event info copied to clipboard!"
                    />
                </div>

                { 
                    showForm && 
                    <EventTicketReservationForm
                        eventData={eventData}
                        eventID={eventData.event_id} canReserve={eventData.can_reserve}
                    />
                }
                
                <EventRecommendationsList eventID={eventData.event_id} />
            </div>
        );
    }

    return HardcodedEvents[eventData.event_id](eventData, showForm);
}
