import { Beat, Song, TransitionSong } from './RhythmSong.js';

function parseSong(song, score) {
    let barNum = 0;
    for (let bar of score.trim().split('|')) {
	bar = bar.trim();
	if (bar.length <= 0) {
	    continue;
	}
	for (let i = 0; i < 8; i++) {
	    if (bar[i] === '.') {
		continue;
	    }
	    song.add(new Beat(barNum, i / 2));
	}
	barNum++;
    }
}

let s = new Song();
s.add(new Beat(0, 0));
s.add(new Beat(0, 0.5));
s.add(new Beat(0, 1.5));
s.add(new Beat(0, 2));
s.add(new Beat(0, 3));
s.setAnswers([0, 0.5, 1.5, 2, 3])
s.setName('Basic Beat');
s.id = 'song1';
    
let s2 = new Song();
s2.add(new Beat(1, 0));
s2.add(new Beat(1, 1));
s2.add(new Beat(1, 2));
s2.add(new Beat(1, 3));
s2.setAnswers([0, 1, 2, 3])
s2.setName('Four On the Floor');
s2.id = 'song2';


let Illusions = new Song(114)
    .setName('Real Illusions');
Illusions.id = 'real_illusions';
let iscore = `
o..o....|o..o....|o..o....|o..o..o.|o..o....|o..o....|o..o....|o..o..o.|

o..o....|o..o....|o..o....|o..o..o.|o..o....|o..o....|o..o....|o..o..o.|
o..o..o.|o..o..o.|o..o..o.|o..o..oo|o..o..o.|o..o..o.|o..o..o.|o..o..oo|

o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..ooo|
o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..ooo|



o..o....|o..o....|o..o....|o..o..o.|o..o....|o..o....|o..o....|o..o..o.|

ooooo...|o..o....|ooooo...|o..o....|ooooo...|o..o....|ooooo...|o..o..o.|
o..o...o|........|o..o..oo|........|o..o...o|........|o..o..oo|........|

oo.oo.oo|.oo.oo..|o..o....|o..o....|oo.oo.oo|.oo.oo..|o..o....|o..o....|
oo.oo.oo|.oo.oo..|o..o....|o..o....|oo.oo.oo|.oo.oo..|o..o....|o..o....|


o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|
o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|o.o..oo.|

o...o...|o...o...|o......|o......|o...o...|o...o...|o..o..o.|.o..o.o.|
o...o...|o...o...|o......|o......|o...o...|o...o...|o..o..o.|.o..o.o.|
.....ooo|.....ooo|........|........|........|........|........|........|
`;
parseSong(Illusions, iscore);


let MoreThanWords = new Song(130)
    .setName('More Than Words');
MoreThanWords.id = 'more_than_words';
let words_score = `
........|........|........|........|........|........|........|........|

o.......|o.......|o.......|o.......|o.......|o.......|o.......|o.......|

o..o....|o..o....|o..o....|o..o....|o..o....|o..o....|o..o....|o..o....|

o..o..o.|o..o..o.|o..o..o.|o..o..o.|o..o..o.|o..o..o.|o..o..o.|o..o..o.|
o..o..o.|o..o..o.|o..o..o.|o..o..o.|o..o..o.|o..o..o.|o..o..o.|o..o..o.|

..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|
..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|



o..o..o.|o..o..o.|o..o..o.|oo.o.oo.|o..o..o.|o..o..o.|o..o..o.|oo.o.oo.|
o..o..o.|o..o..o.|o..o..o.|oo.o.oo.|o..o..o.|o..o..o.|o..o..o.|oo.o.oo.|

o.......|o.......|o.......|o...o...|o.......|o.......|o.......|o...o...|
o...o...|o...o...|o...o...|o...o...|o...o...|o...o...|o...o...|o...o...|

o.o.o.o.|o.o.o...|

..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|
..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|..o...o.|

o..o....|o..o....|o..o....|o..o....|o..o....|o..o....|o..o....|o..o....|
o..o....|o..o....|o..o....|o..o....|o..o....|o..o....|o..o....|o..o....|


o..o....|o..o....|o..o....|o..o....|o..o....|o..o....|o..o....|o..o....|
o.o...o.|o.o...o.|o.o...o.|o.o...o.|o.o...o.|o.o...o.|o.o...o.|o.o...o.|
o.o...o.|..o...o.|o.o...o.|..o...o.|o.o...o.|..o...o.|o.o...o.|..o...o.|

o.......|o.......|o.......|o.......|........|........|........|........|
........|........|........|........|........|........|........|........|
`;
parseSong(MoreThanWords, words_score);

let GiantPeach = new Song(140)
    .setName('Giant Peach');
let peach_score = `
........|........|........|........|........|........|........|........|
o.......|o...o...|o.......|o...o...|o.......|o...o...|o.......|o...o...|

o.o...o.|o.o...o.|o...o...|o...o...|o.o...o.|o.o...o.|o...o...|o...o...|
o...o.o.|o...o.o.|o...o...|o...o...|o...o.o.|o...o.o.|o...o...|o...o...|

..o.o.o.|..o.o.o.|..o.o.o.|..o.o.o.|..o.o.o.|..o.o.o.|..o.o.o.|..o.o.o.|
o.o.o.o.|o.o.oooo|o.o.o.o.|o.o.oooo|o.o.o.o.|o.o.oooo|o.ooo.oo|o.ooo.oo|


o.ooo.oo|o.ooo.o.|o.ooo.oo|o.ooo.o.|o.ooo.oo|o.ooo.o.|o.ooo.oo|o.ooo.o.|
o.ooo.oo|o.ooo.o.|o.ooo.oo|o.ooo.o.|o.ooo.oo|o.ooo.o.|o.ooo.oo|o.ooo.oo|

o.o.o.o.|oo.o....|o.o.o.o.|o.......|o.o.o.o.|oo.o....|o.......|o...o.o.|

o.ooo.oo|o.ooo.o.|o.ooo.oo|o.ooo.o.|o.ooo.oo|o.ooo.o.|o.ooo.oo|o.ooo.o.|
o.ooo.oo|o.ooo.o.|o.ooo.oo|o.ooo.o.|o.ooo.oo|o.ooo.o.|oooooooo|oooooooo|

o.o.o.oo|.o..o.o.|o.o.o.oo|.o..o.o.|o.ooo..o|.o.oooo.|o.ooo..o|.o.oooo.|
o.o.o.oo|.o..o.o.|o.o.o.oo|.o..o.o.|o.ooo..o|.o.oooo.|o.ooo..o|.o.oooo.|
o.......|........|o.......|........|


o.......|o.......|o.......|o.......|o.o.....|o.o.....|o.o.....|o.o.....|
o...o...|o...o...|o...o...|o...o...|o...o...|o...o...|o...o...|o...o...|
o...o.o.|o...o.o.|o...o.o.|o...o.o.|o.o.o.o.|o.o.o.o.|o.o.o.o.|o.......|

o...o...|ooo.ooo.|o...o...|ooo.ooo.|o...o...|ooo.ooo.|o...o...|ooo.ooo.|
o...o...|ooo.ooo.|o...o...|ooo.ooo.|o...o...|ooo.ooo.|o...o...|ooo.ooo.|
o.......|........|........|........|........|........|........|........
`;

parseSong(GiantPeach, peach_score);
GiantPeach.addLyrics([

    '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '',

    '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '',

    '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '',

    "I got change in a pocket somewhere",
    "I got change in a pocket somewhere",
    "Got my letter in the box",
    "Got my letter in the box",
    "Tired of waiting for the bus to nowhere",
    "Tired of waiting for the bus to nowhere",    
    "And tired of chasing the stone fox",
    "And tired of chasing the stone fox",


    "I don't tell lies no more, ya know",
    "I don't tell lies no more, ya know",    
    "I just don't tell the truth",
    "I just don't tell the truth",    
    "I don't know what I'm looking for, ya know",
    "I don't know what I'm looking for, ya know",    
    "But I'm not looking for proof",
    "But I'm not looking for proof",    

    '', '', '', '', '', '', '', '',
    
    "I have a tricky love affair, ya know",
    "I have a tricky love affair, ya know",
    "With the place where I grew up",
    "With the place where I grew up",
    "But it knows I'll never leave, ya know",
    "But it knows I'll never leave, ya know",
    "I never left it much",
    "I never left it much",
    
    "I hate to see it despair, oh, don't you do it",
    "I hate to see it despair, oh, don't you do it",
    "I hate to see it screw up",
    "I hate to see it screw up",
    "But it knows I can hardly grieve",
    "But it knows I can hardly grieve",
    "When it's hard to give a, hard to give a... aAAAA",
    "When it's hard to give a, hard to give a... aAAAA",

    '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '',
    '', '', '', '',

    "Don't know what keeps me here",
    "It's not the boy giving the funny feels",
    "Don't know what keeps me here",
    "It's not the P to pay for the wheel",
    "What the hell keeps me here?",
    "In a dark old town that I've adored",
    "The rules don't seem so clear",
    "And change, it feels like fear, it's all you know",

    '', '', '', '',
    'Our', 'dark', 'and pretty', 'town',
    'Our dark', 'and pretty town',
    'Our dark', 'and handsome town',
    'Our dark and pretty town',
    'Our dark and pretty town',
    'My dark and pretty town',
    'My dark and pretty town',
    
    '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '',
    '',
]);
GiantPeach.id = 'giant_peach';

let st = new TransitionSong();

let songlist = {}
songlist[s.id] = s;
songlist[s2.id] = s2;
songlist[GiantPeach.id] = GiantPeach;
songlist[Illusions.id] = Illusions;
songlist[MoreThanWords.id] = MoreThanWords;

export { s, s2, st, songlist, GiantPeach, MoreThanWords, Illusions };


