import React from 'react';

import Loading from '../common/Loading.js';

import ShowPerformanceCard from '../show/ShowPerformanceCard.js';

export default function EventShowProgram({event}) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [show, setShow] = React.useState(null);

    // TODO Refactor as hook
    React.useEffect(() => {
	fetch('/show/status/?event_id=' + event)
            .then(res => res.json())
            .then(
		(res) => {
		    setShow(res.show);
  		    setIsLoaded(true);
		},
		(error) => {
  		    setIsLoaded(true);
		}
            )
    }, []);

    if (!isLoaded) {
        return <Loading />;
    }

    if (!show) {
	return null;
    }
    
    return (
	<ShowPerformanceCard
	    show={show}
	    simple={true}
	    title="Program (Live Updated)"
	/>
    );
}

