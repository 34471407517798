import './RhythmBackground.css';

import React from 'react';

import * as Consts from './RhythmConsts.js';
import { useConductedAnimationFrame, useUniqueID} from './RhythmHook.js';

export function RhythmBackground({name, flash}) {
    const componentId = useUniqueID();//React.useId();
    const [pulse, setPulse] = React.useState(0);
    
    useConductedAnimationFrame((cb, pb, beat) => {
	if (beat % Consts.NOTE_4 !== 0) { // Only care about quarter notes.
	    return;
	}
	setPulse(prevPulse => (prevPulse + 1) % 2);
    }, componentId, true); // listen to all quantized beats

    return (
	<div className="background">
	    <div key={"pulse" + pulse} className="logo pulse">
		<div className={name} />
	    </div>
	</div>
    );
}

export function RhythmClientBackground({name}) {
    return (
	<div className="background">
	    <div className="logo pulse">
		<div className={name} />
	    </div>
	</div>
    );
}

export function RhythmFlash({flash}) {
    return (
	<div
	    className={"overlayScreen " + (flash ? 'flash' : '')}
	/>
    );
}
