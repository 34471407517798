import React from 'react';

import { RhythmContext } from './RhythmContext.js';
import * as Consts from './RhythmConsts.js';
import { useConductedAnimationFrame, useUniqueID } from './RhythmHook.js';

export function RhythmRing({beat, show, clue, radius=Consts.ORBIT_R}) {
    const componentId = useUniqueID();// React.useId();
    const [pulse, setPulse] = React.useState(0);
    const [hide, setHide] = React.useState(false);
    
    useConductedAnimationFrame((cb, pb) => {
	setPulse(prevPulse => (prevPulse + 1) % 2);
	// setHide(true);
    }, componentId, beat);

    // Show the beat after hiding it above
    useConductedAnimationFrame((cb, pb) => {
	// setHide(false);
    }, componentId + '_show', (beat + Consts.NOTE_4) % Consts.BAR);

    let a = beat * 2 * Math.PI / Consts.BAR;
    let x = radius * Math.sin(a);
    let y = -radius * Math.cos(a);

    const style = {
	"--beat-pos-x": x.toFixed(4) + 'px',
	"--beat-pos-y": y.toFixed(4) + 'px',
    };

    let classes = [
	'circle',
	show && !hide ? 'show' : 'hide',
    ].join(' ');

    return (
    	<div className={classes} style={style}>
	    <span className={'pulse' + pulse} />
	    <b className="rhythmClue">
		{clue}
	    </b>
	</div>
    );
}

export function RhythmSheet() {
    const componentId = useUniqueID();//React.useId();
    const { conductorRef } = React.useContext(RhythmContext);

    const [song, setSong] = React.useState(null);
    const [bar, setBar] = React.useState(0);
    const [beat, setBeat] = React.useState(0);
    
    const onSongChange = React.useCallback((newSong) => {
	setSong(newSong);
    }, []);

    // This hook will only run once each when the conductor is null and when
    // the reference is set. Don't except anything else when the internal
    // guts of the conductor changes.
    React.useEffect(() => {
	if (!conductorRef.current) {
	    return;
	}
	setSong(conductorRef.current.currentSong);
	conductorRef.current.subscribeSongChange(onSongChange, 'song');
    }, [conductorRef.current]);

    useConductedAnimationFrame((cb, pb, crossBeat) => {
	let bar = Math.floor(cb / Consts.BAR);
	let beat = cb % Consts.BAR;
	setBar(bar);
	setBeat(beat);
    }, componentId, true);

    
    const renderRhythmClue = () => {
	if (!song) {
	    return null;
	}
	return (
	    <b className={"songClue " + song.getClueClass()}>
		{song.getClue()}
	    </b>
	);
    };
    
    return (
	<>
            {Array.from(
                Array(Consts.BAR / Consts.NOTE_8), (e, i) => {
                    let v = i * Consts.NOTE_8;
		    let b = bar;
		    if (beat > v) {
			// show the beat on the next bar since we've passed it.
			b += 1;
		    }
                    return (
			<RhythmRing
			    key={"beat-" + i}
			    show={song ? song.shouldShow(b, v) : false}
			    clue={song ? song.getBeatClue(b, v) : null}
			    beat={v}
			/>
		    );
                },
            )}
	    {renderRhythmClue()}
	</>
    );
}
