import { Container } from 'react-bootstrap';
import {Routes, Route, useSearchParams} from 'react-router-dom';

import NotFound from '../home/NotFound.js';

import RhythmGame from './RhythmGame.js';
import RhythmClient from './RhythmClient.js';
import RhythmHost from './RhythmHost.js';
import { RhythmProvider } from './RhythmContext.js';

export default function QuizAppRouter() {
    let [searchParams] = useSearchParams();
    let quizID = searchParams.get('quiz_id');
    let h = searchParams.get('h');

    return (
        <Routes>
	    <Route path='*' element={
		       <RhythmProvider>
			   <RhythmGame />
		       </RhythmProvider>		       
		   }
	    />
	    <Route path="client" element={
		       <RhythmProvider>
			   <RhythmClient />
		       </RhythmProvider>
		   }
	    />
	    <Route path="cocoa" element={
		       <RhythmProvider>
			   <RhythmHost />
		       </RhythmProvider>
		   }
	    />		
        </Routes>
    );
}
