import React from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';

import Loading from '../common/Loading.js';
import { getWithData } from '../common/SecurityUtils.js';

import { ShowProgramCompact } from './ShowProgram.js';


export default function ShowPerformanceCard({show, title='Program', simple=false}) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [programs, setPrograms] = React.useState([]);    
    
    React.useEffect(() => {
        setIsLoaded(false);
        getWithData('/show/fetch_all_programs/', {show: show.show_id})
            .then(res => res.json())
            .then(
                (payload) => {
		    setPrograms(payload.programs);
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                }
            )
    }, []);

    if (!isLoaded) {
        return <Loading />;
    }

    const renderPerformances = (idx = 0) => {
	let ps = programs ? programs.filter(p => p.order === idx) : [];
	let className = 'programEmpty';
	if (ps.length === 1) {
	    className = 'programFilled';
	} else if (ps.length > 1) {
	    className = 'programOverflow';
	}
	return (
	    <Row xs="auto" className={className}>
                {ps.map(x => {
		    return (
			<Col className="programItem">
			    <ShowProgramCompact program={x} simple={simple} />
			</Col>
		    );
		})}
            </Row>
	);
    };

    const renderPending = () => {
	if (programs.filter(p => p.order === 0).length === 0) {
	    return null;
	}
	return (
	    <>
		<ListGroup.Item></ListGroup.Item>
		<ListGroup.Item>
		    Not Yet Slated
		</ListGroup.Item>
		<ListGroup.Item>
		    {renderPerformances(0)}
		</ListGroup.Item>
	    </>
	);
    };

    return (
	<Card className="mt-3">
	    <Card.Header>
		<Card.Title>{title}</Card.Title>
	    </Card.Header>
	    <ListGroup variant="flush">
		{Array.from(
		    Array(show.max_programs), (e, i) => {
			return (
			    <ListGroup.Item>
				<Row className="scheduledProgram">
				    <Col className="programListNumbering">
					{i + 1}
				    </Col>
				    <Col>
					{renderPerformances(i + 1)}
				    </Col>
				</Row>
			    </ListGroup.Item>				
			);
		    }
		)}
		{!simple && renderPending()}
	    </ListGroup>
	</Card>
    );
}
